
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
  watchEffect,
  toRaw,
  createVNode,
} from 'vue'
import { dateFormats } from '@/services/helpers/constants'
import TableInvoiceConsignment from './TableInvoiceConsignments.vue'
import { InvoiceFormData } from '@/types/appcontracts/InvoiceFormData'
import { IInvoiceCustomerDetails } from '@/types/interfaces/IInvoiceCustomerDetails'
import { IAddressFormat } from '@/types/interfaces/IAddressFormat'
import { IInvoiceCompanyDetails } from '@/types/interfaces/IInvoiceCompanyDetails'
import { IInvoiceConsignmentFilter } from '@/types/interfaces/IInvoiceConsignmentFilter'
import { useStore } from 'vuex'
import { ICustomersSearchMapping } from '@/types/interfaces/ICustomersSearchMapping'
import { useSearchMapping } from '@/hooks/useSearchMappingHook'
import { IVendorSearchMapping } from '@/types/interfaces/IVendorSearchMapping'
import * as actions from '@/store/actions.type'
import { IInvoiceConsignmentQuery } from '@/types/interfaces/IInvoiceConsignmentQuery'
import { ConsignmentsDocument } from '@/types/firebaseCollectionContracts/ConsignmentsDocument'
import { INameValuePair } from '@/types/interfaces/INameValuePair'
import dayjs from 'dayjs'
import { IAddonGroupType } from '@/types/interfaces/IAddonGroupType'
import * as utils from '@/services/helpers'
import { AddonApplyType } from '@/types/enums/AddonApplyType'
// import InvoicePrintPage from '../view/InvoicePrintPage.vue'
import { IAppDocument } from '@/types/interfaces/IAppDocument'
import { CompaniesDocument } from '@/types/firebaseCollectionContracts/CompaniesDocument'
import { IInvoiceCounter } from '@/types/interfaces/IInvoiceCounter'
import {
  RedoOutlined,
  FileSearchOutlined,
  IdcardOutlined,
  ExclamationCircleOutlined,
  NumberOutlined,
  EditOutlined,
} from '@ant-design/icons-vue'
import { CustomersDocument } from '@/types/firebaseCollectionContracts/CustomersDocument'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { IInvoiceConsignment } from '@/types/interfaces/IInvoiceConsignment'
import { InvoiceDiscountType } from '@/types/enums/InvoiceDiscountType'
import { ICompanyBankAccount } from '@/types/interfaces/ICompanyBankAccount'
import { InvoiceStatusTypes } from '@/types/enums/InvoiceStatusTypes'
import { Modal } from 'ant-design-vue'
import ModalSlot from '@/components/General/ModalSlot.vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 8 },
}

const getIntialAddress = () => {
  const initialAddress: IAddressFormat = {
    country: 'India',
    attention: null,
    city: null,
    state: null,
    address: null,
    pincode: null,
  }
  return initialAddress
}

const initialCustomer: IInvoiceCustomerDetails = {
  id: null,
  cid: null,
  gstin: null,
  name: null,
  billAddress: getIntialAddress(),
  shipAddress: getIntialAddress(),
}

const initialCompany: IInvoiceCompanyDetails = {
  id: null,
  brid: null,
  name: null,
  gstin: null,
  address: getIntialAddress(),
  bankAccount: null,
  pan: null,
  email: null,
  phone: null,
  photoUrl: null,
}

const initialFilters: IInvoiceConsignmentFilter = {
  bookingRange: null,
  vendorList: [],
  carrierList: [],
}

const getInitialState = () => {
  const initialFormState: InvoiceFormData = {
    invid: null,
    customer: { ...initialCustomer },
    company: { ...initialCompany },
    placeOfSupply: 'West Bengal (19)',
    invoiceDate: null,
    dueDate: null,
    invoiceStatus: InvoiceStatusTypes.Draft,
    paymentTerms: null,
    filters: { ...initialFilters },
    consignments: [],
    taxes: { singles: [], groups: [] },
    serviceCharges: { singles: [], groups: [] },
    subTotal: 0,
    discount: {
      type: InvoiceDiscountType.Percent,
      value: 0,
      amount: 0,
    },
    adjustment: 0,
    finalAmount: 0,
    balanceDue: 0,
    paymentMade: null,
    appliedTaxes: [],
    appliedCharges: [],
    isManualInv: false,
  }

  return initialFormState
}

const getNameValuePair = (pr: INameValuePair<number>) => {
  const pair: INameValuePair<number> = {
    name: pr.name,
    value: pr.value,
  }
  return pair
}

const getAddOnGroup = (gr: IAddonGroupType) => {
  const grp: IAddonGroupType = {
    name: gr.name,
    applyType: gr.applyType,
    values: gr.values.map((x) => getNameValuePair(x)),
  }
  return grp
}

export default defineComponent({
  components: {
    TableInvoiceConsignment,
    // InvoicePrintPage,
    ModalSlot,
    RedoOutlined,
    FileSearchOutlined,
    IdcardOutlined,
    NumberOutlined,
    EditOutlined,
  },
  setup() {
    const store = useStore()
    const { vendorServicesSearchList, vendorsSearchList } = useSearchMapping()
    const formRef = ref()
    const formState = reactive(getInitialState())
    const invoiceBorder = ref<string>('')
    const isPreviewVisible = ref<boolean>(false)
    const selectedInvoiceNumber = ref<number | null>(null)
    const selectedTax = ref<INameValuePair<number> | IAddonGroupType | null>(null)
    const selectedServiceCharges = ref([])
    const selectedServiceChargeGroup = ref<IAddonGroupType | null>(null)
    const selectedPaymentTerm = ref<string | null>(null)
    const selectedBankAccount = ref<string | null>(null)
    const fetchedConsignments = ref<ConsignmentsDocument[] | null>(null)
    const isLoading = ref<boolean>(false)
    const isServiceChargeEditModal = ref<boolean>(false)
    const consignmentLoading = ref<boolean>(false)
    const totalServiceChargeValue = computed(() => {
      return formState.appliedCharges?.reduce((prev, curr) => prev + curr.value, 0) || 0
    })
    const totalTaxValue = computed(() => {
      return formState.appliedTaxes?.reduce((prev, curr) => prev + curr.value, 0) || 0
    })
    const totalDiscount = computed(() => {
      const val =
        formState.discount.type === InvoiceDiscountType.Percent
          ? utils.getValueOfPercentage(
              formState.subTotal + totalServiceChargeValue.value,
              formState.discount.value || 0,
            )
          : formState.discount.value
      return val
    })

    // Added isNaN for adjs as it can take up "-" causing issue
    const totalTaxableAmount = computed(
      () =>
        formState.subTotal +
        totalServiceChargeValue.value -
        (formState.discount.amount || 0) +
        (isNaN(formState.adjustment || 0) ? 0 : formState.adjustment || 0),
    )
    const customerList = computed<IAppDocument<CustomersDocument>[]>(
      () => store.getters['customers/customerList'] as IAppDocument<CustomersDocument>[],
    )
    const companyList = computed(
      () => store.getters[`companies/companyList`] as IAppDocument<CompaniesDocument>[],
    )
    const invPfx = computed(() => {
      const inv = 'INV'
      const pfx =
        companyList.value?.find((x) => x.id === formState.company.id)?.doc?.invoicePrefix || 'XX'
      const dt = dayjs(formState.invoiceDate)
      const yymm = dt.isValid() ? dt.format('YYMM') : 'YYMM'
      const paddedNum = selectedInvoiceNumber.value?.toString().padStart(4, '0') || 'NNNN'
      return `${inv}${pfx}${yymm}${paddedNum}`
    })
    const companyOptions = computed(() => {
      return companyList.value?.map((comp) => {
        return {
          value: comp.id,
          label: `${comp.doc?.name}`,
          key: comp.id,
          options: comp.doc?.branches?.map((z) => {
            return {
              value: z.brid,
              label: `${z.name}`,
              key: z.brid,
            }
          }),
        }
      })
    })

    const filteredBankOptions = computed(() => {
      return companyList.value
        ?.find((x) => x.id === formState.company.id)
        ?.doc?.bankAccounts?.map((z) => {
          return {
            value: z.name,
            label: `${z.name}`,
            key: z.name,
            bankAccount: z,
          }
        })
    })
    const serviceChargeOptions = computed(() => {
      const singles = store.state.appGlobals.appConfigSettings?.serviceCharges
        ?.singles as INameValuePair<number>[]
      return (
        singles?.map((x) => {
          return {
            value: x.name,
            label: x.name,
            key: x.name,
            charge: getNameValuePair(x),
          }
        }) || []
      )
    })
    const serviceChargeGroupOptions = computed(() => {
      const groups = store.state.appGlobals.appConfigSettings?.serviceCharges
        ?.groups as IAddonGroupType[]
      return (
        groups?.map((x) => {
          return {
            value: x.name,
            label: x.name,
            key: x.name,
            charge: getAddOnGroup(x),
          }
        }) || []
      )
    })
    const taxOptions = computed(() => {
      const singles = store.state.appGlobals.appConfigSettings?.taxes?.singles as INameValuePair<
        number
      >[]
      const groups = store.state.appGlobals.appConfigSettings?.taxes?.groups as IAddonGroupType[]
      const ops = [
        {
          label: 'Tax',
          options:
            singles?.map((x) => {
              return {
                value: x.name,
                label: x.name,
                key: x.name,
                type: 'single',
                tax: x,
              }
            }) || [],
        },
        {
          label: 'Tax Groups',
          options:
            groups?.map((x) => {
              return {
                value: x.name,
                label: x.name,
                key: x.name,
                type: 'group',
                tax: x,
              }
            }) || [],
        },
      ]
      return ops
    })
    const paymentTermsOptions = computed(
      () =>
        (store.state.appGlobals.appConfigSettings?.paymentTerms as INameValuePair<number>[])?.map(
          (x) => {
            return {
              value: x.name, //`${x.name}_${x.value}`,
              label: x.name,
              key: x.name,
              term: x,
            }
          },
        ) || [],
    )
    const customersOptions = computed(() => {
      return store.state.customers.customersSearchList?.map((cust: ICustomersSearchMapping) => {
        return {
          value: cust.id,
          label: `${cust.displayName} (${cust.cid})`,
          key: cust.id,
        }
      })
    })
    const vendorToServiceOptions = computed<Array<any>>(() => {
      return vendorsSearchList.value?.map((v: IVendorSearchMapping) => {
        return {
          label: v.displayName,
          vendorId: v.id,
          options: [
            ...vendorServicesSearchList.value
              .filter((z) => z.vendorId === v.id)
              ?.map((el) => {
                return {
                  label: el.name,
                  value: el.id,
                  key: el.id,
                }
              }),
          ],
        }
      })
    })

    const invoiceCounterSearchDoc = computed(() => {
      const invDate = dayjs(formState.invoiceDate)
      const pfx = companyList.value?.find((x) => x.id === formState.company.id)?.doc?.invoicePrefix
      const invoiceCountQueryParam = {
        month: invDate?.month(),
        year: invDate?.year(),
        prefix: pfx || '',
      }
      const invoiceCounterList = store.getters['companies/getInvoiceCounter'](
        formState.company.id,
      ) as IInvoiceCounter[]
      return invoiceCounterList?.find(
        (x) =>
          x.month === invoiceCountQueryParam.month &&
          x.year === invoiceCountQueryParam.year &&
          x.prefix === invoiceCountQueryParam.prefix,
      )
    })
    const manualInvList = computed(
      () => store.getters['companies/getManualInv'](formState.company.id) as string[],
    )

    const nextInvoiceNumber = computed(() => {
      let mx = 0
      const nums = invoiceCounterSearchDoc.value?.numbers || []
      if (nums.length > 0) {
        mx = Math.max(...nums)
      }
      return mx + 1
    })

    const invoiceGaps = computed(() => {
      const gaps = []
      const nums = invoiceCounterSearchDoc.value?.numbers || []

      for (let i = 1; i < nextInvoiceNumber.value; i++) {
        if (!(nums.indexOf(i) >= 0)) {
          gaps.push(i)
        }
      }
      return gaps
    })

    const showGaps = () => {
      Modal.info({
        title: `${invoiceGaps.value?.length || 0} Unused Invoice Numbers.`,
        content: createVNode('h5', {}, `${invoiceGaps.value}`),
        onOk() {},
      })
    }

    // const bookingRange = ref<string[] | null>([])

    const validateInvoiceId = () => {
      if (formState.isManualInv && formState.invid) {
        if (manualInvList.value.includes(formState.invid.trim())) {
          return Promise.reject('Invoice Number already exists')
        }
        return Promise.resolve()
      }
      const nums = invoiceCounterSearchDoc.value?.numbers || []
      if (selectedInvoiceNumber.value && nums.indexOf(selectedInvoiceNumber.value) >= 0) {
        invoiceBorder.value = 'border-danger'
        return Promise.reject('Invoice Number already exists')
      } else {
        if (selectedInvoiceNumber.value && selectedInvoiceNumber.value > nextInvoiceNumber.value) {
          invoiceBorder.value = 'border-warning'
        } else {
          invoiceBorder.value = 'border-success'
        }
      }
      return Promise.resolve()
    }

    const rules = computed(() => {
      return {
        invid: [
          { required: true, message: 'Please enter invoice id', trigger: 'blur', type: 'string' },
          {
            validator: validateInvoiceId,
            trigger: 'blur',
            type: 'string',
            required: 'true',
          },
        ],
        placeOfSupply: [
          {
            required: true,
            message: 'Please enter Place of Supply',
            trigger: 'blur',
            type: 'string',
            whitespace: true,
          },
        ],
        customer: {
          id: [
            {
              required: true,
              message: 'Please select a Customer',
              trigger: 'blur',
              type: 'string',
            },
          ],
        },
        company: {
          id: [
            {
              required: true,
              message: 'Please select a Company',
              trigger: 'blur',
              type: 'string',
            },
          ],
          brid: [
            {
              required: true,
              message: 'Please select a Branch',
              trigger: 'blur',
              type: 'string',
            },
          ],
        },
        brid: [
          {
            required: true,
            message: 'Please select a Branch',
            trigger: 'change',
            type: 'string',
          },
        ],
        invoiceDate: [
          { required: true, message: 'Please enter invoice date', trigger: 'blur', type: 'string' },
        ],
        dueDate: [
          { required: true, message: 'Please enter due date', trigger: 'blur', type: 'string' },
        ],
        filters: {
          bookingRange: [
            { required: true, message: 'Please enter range', trigger: 'blur', type: 'array' },
          ],
        },
      }
    })

    const handleCustomerSelect = async () => {
      let cust = customerList.value.find((x) => x.id === formState.customer.id)?.doc
      if (!cust) {
        const searchList = [formState.customer.id]
        const res: IAppDocument<CustomersDocument>[] = await store.dispatch(
          `customers/${actions.CustomersAction.FETCH_CUSTOMERS_BY_IDLIST}`,
          {
            idList: searchList,
          },
        )
        cust = res.find((x) => x.id === formState.customer.id)?.doc
      }
      formState.customer.name = cust?.displayName || null
      formState.customer.gstin = cust?.gstin || null
      formState.customer.billAddress = cust?.primaryAddress || getIntialAddress()
      formState.customer.shipAddress = cust?.primaryAddress || getIntialAddress()
      formState.customer.cid = cust?.cid || null
    }

    const handleCompanyBranchSelect = () => {
      formState.company.bankAccount = null
      selectedBankAccount.value = null
      const comp = companyList.value.find((x) =>
        x.doc.branches.some((z) => z.brid === formState.company.brid),
      )
      formState.company.id = comp?.id || null

      if (comp) {
        formState.company.photoUrl = comp.doc?.photoUrl || null
        formState.company.pan = comp.doc?.pan || null
        const branch = comp?.doc?.branches.find((x) => x.brid === formState.company.brid)
        if (branch) {
          formState.company.name = comp?.doc?.name
          formState.company.address = branch.branchAddress
          formState.company.gstin = branch.gstin
          formState.company.email = branch.email
          formState.company.phone = branch.phone
        }
      }
      // Added add the end so that computed values are avaialble.
      formState.company.bankAccount = filteredBankOptions.value?.[0]?.bankAccount || null
      selectedBankAccount.value = filteredBankOptions.value?.[0]?.value || null
    }

    const handleLoadConsignments = async () => {
      consignmentLoading.value = true
      const payload: IInvoiceConsignmentQuery = {
        customerId: formState.customer.id,
        fromDate: new Date(formState.filters.bookingRange?.[0] || ''),
        toDate: new Date(formState.filters.bookingRange?.[1] || ''),
      }
      fetchedConsignments.value =
        (await store.dispatch(
          `consignments/${actions.ConsignmentsAction.FETCH_INVOICE_CONSIGNMENT_DATA}`,
          payload,
        )) || []

      updateInvoiceConsignments()
      consignmentLoading.value = false
    }

    const updateInvoiceConsignments = () => {
      // TODO: Since this is called multiple times, avoid conversion all the time.
      formState.consignments =
        fetchedConsignments.value
          ?.map((x) => {
            const con: IInvoiceConsignment = {
              awb: x.awb,
              rfn: x.rfn,
              bookingDate: dayjs(x.bookingDate).format(dateFormats.momentValueFormat) || null,
              dest: utils.formatDestObject(x.destinationAddress),
              serviceId: x.serviceId,
              carrierServiceId: x.carrierServiceId,
              pcs: x.pcs || 0,
              weight: x.weight,
              amount: x.amount,
            }
            return con
          })
          .filter((x) => {
            if (
              formState.filters.carrierList.length === 0 ||
              formState.filters.carrierList.indexOf(x.serviceId || '') >= 0
            ) {
              return true
            }
            return false
          }) || []
    }

    const handleCarrierSelect = () => {
      updateInvoiceConsignments()
    }

    const handleInvoiceNumberSelect = () => {
      // TODO: Check: Currently not getting called on updating value. So using Watch.
      if (selectedInvoiceNumber.value) {
        formState.invid = invPfx.value
      } else {
        formState.invid = null
      }
    }

    // const handlePaymentTermsSelect = (_: any, ops: any) => {
    //   // TODO: Handle Custom select
    //   const termObj = (ops.term as INameValuePair<number>) || null
    //   if (termObj && termObj.name?.toLowerCase() != 'custom' && formState.invoiceDate) {
    //     formState.dueDate = dayjs(formState.invoiceDate)
    //       .add(termObj.value, 'day')
    //       .format(dateFormats.momentValueFormat)
    //     formRef.value.validate('dueDate')
    //   }
    // }

    const handleBankAccountSelect = (_: any, ops: any) => {
      // TODO: Handle Custom select
      const bankAccObj = (ops.bankAccount as ICompanyBankAccount) || null
      if (bankAccObj) {
        formState.company.bankAccount = bankAccObj
      }
    }

    const handleInvoiceDateChange = async () => {
      // This makes dueDate null but doesnt updates the UI.
      // formState.dueDate = null
      selectedPaymentTerm.value = 'Net7'
      formState.dueDate = dayjs(formState.invoiceDate)
        .add(7, 'day')
        .format(dateFormats.momentValueFormat)
      formRef.value.validate('dueDate')
      const fromDt = dayjs(formState.invoiceDate)
        .subtract(3, 'year')
        .format(dateFormats.momentValueFormat)
      const toDt = dayjs(formState.invoiceDate).format(dateFormats.momentValueFormat)
      formState.filters.bookingRange = [fromDt, toDt]
      formRef.value.validate('filters.bookingRange')
      await handleLoadConsignments()
    }

    const handleTaxSelect = (_: any, ops: any) => {
      formState.taxes.singles = []
      formState.taxes.groups = []

      if (ops) {
        if (ops.type === 'single') {
          // formState.taxes.groups = [] // reset groups
          formState.taxes.singles = [ops.tax]
        } else {
          const grp = ops.tax as IAddonGroupType
          formState.taxes.groups = [grp]
          // formState.taxes.singles = [] // reset singles
        }
      }
      calcTaxes()
    }

    const handleServiceChargeReset = () => {
      console.log('TODO: Reset Service charge values.')
    }

    const handleServiceChargeGroupSelect = (_: any, ops: any) => {
      const charge = (ops?.charge as IAddonGroupType) || null
      if (charge) {
        formState.serviceCharges.groups = [getAddOnGroup(charge)]
      } else {
        formState.serviceCharges.groups = []
      }
      calcServiceCharges()
      // calcTaxes()
    }

    const handleServiceChargeSelect = (_: any, ops: Array<any>) => {
      const charges = ops.map((x) => getNameValuePair(x.charge as INameValuePair<number>))
      formState.serviceCharges.singles = [...charges]
      calcServiceCharges()
      // calcTaxes()
    }

    const calcTaxes = () => {
      const singleTax = formState.taxes?.singles?.[0] || null
      const groupTax = formState.taxes?.groups?.[0] || null
      let type = AddonApplyType.Basic
      const nameValues = []
      formState.appliedTaxes = []

      if (singleTax) {
        nameValues.push(singleTax)
      } else if (groupTax) {
        nameValues.push(...groupTax.values)
        type = groupTax.applyType
      }

      //Calc tax after adding service charges.
      // totalTaxValue.value = .
      calcAddonCharge(type, nameValues, formState.appliedTaxes, totalTaxableAmount.value)
    }

    const calcServiceCharges = () => {
      const singleTax = formState.serviceCharges?.singles || []
      const groupTax = formState.serviceCharges?.groups?.[0] || null
      formState.appliedCharges = []
      const singleNameValues = [] as INameValuePair<number>[]
      const groupNameValues = [] as INameValuePair<number>[]

      singleNameValues.push(...singleTax)
      // totalServiceChargeValue.value =
      calcAddonCharge(AddonApplyType.Basic, singleNameValues, formState.appliedCharges)

      if (groupTax) {
        groupNameValues.push(...groupTax.values)
      }
      // totalServiceChargeValue.value +=
      calcAddonCharge(
        groupTax?.applyType || AddonApplyType.Basic,
        groupNameValues,
        formState.appliedCharges,
      )
      // }
    }

    const calcAddonCharge = (
      type: AddonApplyType,
      values: INameValuePair<number>[],
      appliedArr: INameValuePair<number>[],
      chargeValue: number = formState.subTotal || 0,
    ) => {
      const subTotal = chargeValue
      let newSubTotal = subTotal || 0
      // let per = 0

      if (newSubTotal && newSubTotal > 0) {
        values?.forEach((x) => {
          // Removed percent addition to name format: (${x.value}%)
          // TODO: Add a way to add percent on demand
          const nameFormat = `${x.name}`
          const percentValue = utils.getValueOfPercentage(newSubTotal, x.value)
          // per += percentValue
          appliedArr?.push({ name: nameFormat, value: percentValue })
          if (type === AddonApplyType.InOrder) {
            newSubTotal += percentValue
          }
        })
      }
      // return per
    }

    const handleInvoiceNumberReset = () => {
      selectedInvoiceNumber.value = nextInvoiceNumber.value
    }

    const handleFinish = async () => {
      if (selectedInvoiceNumber.value && selectedInvoiceNumber.value > nextInvoiceNumber.value) {
        Modal.confirm({
          title: 'Selected Invoice Number is not in sequence.',
          content: 'This will create a gap in invoice numbers. Are you sure you want to proceed ?',
          icon: createVNode(ExclamationCircleOutlined),
          okText: 'Yes',
          cancelText: 'No',
          onOk: async () => {
            await addInvoice()
          },
          onCancel() {},
        })
      } else {
        await addInvoice()
      }
    }

    const addInvoice = async () => {
      isLoading.value = true
      const docOperation: DocumentOperation<InvoiceFormData> = {
        action: DocumentActions.CREATE,
        id: null,
        payload: toRaw(formState),
        audit: null,
      }
      const isSuccess = await store.dispatch(
        `invoices/${actions.InvoicesAction.ADD_NEW_INVOICE}`,
        docOperation,
      )
      isLoading.value = false
      if (isSuccess) {
        // TODO: Address and bankAccounts not getting reset.
        // Object.assign(formState, getInitialState())
        // formRef.value.resetFields()
        resetForm()
      }
    }

    // const disabledDueDate = (current: any) => {
    //   // Current is Dayjs Obj.
    //   const curr = dayjs(current.format(dateFormats.momentValueFormat))
    //   return curr && curr < dayjs(formState.invoiceDate)
    // }

    const disabledBookingDate = (current: any) => {
      // This also disables invoice date. May be because of time zone issue.
      const curr = dayjs(current.format(dateFormats.momentValueFormat))
      return curr && curr > dayjs(formState.invoiceDate)
    }

    const resetForm = () => {
      Object.assign(formState, getInitialState())
      isPreviewVisible.value = false
      selectedInvoiceNumber.value = null
      selectedTax.value = null
      selectedServiceCharges.value = []
      selectedServiceChargeGroup.value = null
      selectedPaymentTerm.value = null
      fetchedConsignments.value = null
      isLoading.value = false
    }

    watchEffect(() => {
      formState.finalAmount = utils.fixDecimalValue(totalTaxableAmount.value + totalTaxValue.value)
      formState.balanceDue = formState.finalAmount
      // formState.subTotal + totalTaxValue.value + totalServiceChargeValue.value

      // TODO: Check best place to add this.
      // formState.appliedCharges = appliedCharges.value || []
      // formState.appliedTaxes = appliedTaxes.value || []
    })

    watch(
      [nextInvoiceNumber, () => formState.invoiceDate, () => formState.company.id],
      ([newInv, newDt, newComp], [oldInv, oldDt, oldComp]) => {
        // If prev value was set by nextInvNum then update.
        // Else its possible that it was manually changed.
        // But if the change is due to change in comp or date, then also update.
        if (
          !selectedInvoiceNumber.value ||
          newDt != oldDt ||
          newComp != oldComp ||
          selectedInvoiceNumber.value === oldInv
        ) {
          selectedInvoiceNumber.value = newInv
        }
      },
    )

    watch([selectedInvoiceNumber, invPfx], () => {
      if (selectedInvoiceNumber.value && !formState.isManualInv) {
        formState.invid = invPfx.value
      }
    })

    watch(
      () => formState.isManualInv,
      (newv) => {
        if (newv) {
          formState.invid = ''
        } else {
          formState.invid = invPfx.value
        }
      },
    )

    watch(selectedPaymentTerm, (newv) => {
      const termObj = paymentTermsOptions.value.find((x) => x.value === newv)?.term || null
      formState.paymentTerms = termObj
    })

    watch(
      () => formState.consignments,
      () => {
        formState.subTotal = utils.fixDecimalValue(
          formState.consignments?.reduce((prev, curr) => (prev += curr.amount || 0), 0) || 0,
        )
      },
    )

    watch(totalDiscount, (newv) => {
      formState.discount.amount = newv
    })

    watch(
      () => formState.subTotal,
      () => {
        calcServiceCharges()
      },
    )

    watch(totalTaxableAmount, () => {
      calcTaxes()
    })

    watch(isServiceChargeEditModal, (newv) => {
      if (!newv) {
        calcServiceCharges()
      }
    })

    // watch(
    //   () => formState.invoiceDate,
    //   () => {
    //     handleInvoiceDateChange()
    //   },
    // )

    return {
      formRef,
      formState,
      rules,
      labelCol,
      wrapperCol,
      dateFormats,
      fetchedConsignments,
      customersOptions,
      vendorToServiceOptions,
      selectedTax,
      selectedServiceCharges,
      companyOptions,
      serviceChargeOptions,
      taxOptions,
      // paymentTermsOptions,
      handleLoadConsignments,
      // handlePaymentTermsSelect,
      // selectedPaymentTerm,
      handleTaxSelect,
      utils,
      serviceChargeGroupOptions,
      selectedServiceChargeGroup,
      handleServiceChargeSelect,
      handleServiceChargeGroupSelect,
      isPreviewVisible,
      invPfx,
      selectedInvoiceNumber,
      handleInvoiceNumberReset,
      handleInvoiceNumberSelect,
      handleCompanyBranchSelect,
      handleCustomerSelect,
      handleFinish,
      isLoading,
      // disabledDueDate,
      disabledBookingDate,
      handleInvoiceDateChange,
      filteredBankOptions,
      handleBankAccountSelect,
      selectedBankAccount,
      handleCarrierSelect,
      consignmentLoading,
      showGaps,
      invoiceGaps,
      invoiceBorder,
      isServiceChargeEditModal,
      handleServiceChargeReset,
    }
  },
})
