
// import { ConsignmentsDocument } from '@/types/firebaseCollectionContracts/ConsignmentsDocument'
import { IInvoiceConsignment } from '@/types/interfaces/IInvoiceConsignment'
import { computed, defineComponent, PropType } from 'vue'

const pagination = {
  // position: 'top',
  showSizeChanger: true,
  showQuickJumper: true,
}

const columns = [
  {
    title: 'Product',
    key: 'product',
    slots: { customRender: 'product' },
  },
  {
    title: 'Shipment Date',
    dataIndex: 'bookingDate',
    key: 'bookingDate',
    slots: { customRender: 'renderDate' },
  },
  {
    title: 'AWB',
    dataIndex: 'awb',
    key: 'awb',
  },
  {
    title: 'Refno',
    dataIndex: 'rfn',
    key: 'rfn',
  },
  {
    title: 'Dest',
    dataIndex: 'dest',
    key: 'dest',
  },
  {
    title: 'Pcs',
    dataIndex: 'pcs',
    key: 'pcs',
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
    key: 'weight',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
]

export default defineComponent({
  name: 'TableInvoiceConsignment',
  props: {
    invDataSource: {
      type: Object as PropType<IInvoiceConsignment[] | null>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const dataSource = computed(() => props.invDataSource)
    const loading = computed(() => props.isLoading)
    return {
      columns,
      pagination,
      dataSource,
      loading,
    }
  },
})
